<template>
  <div>
    <empty-resource-table v-if="!transactionsPage[0] && !loadingTable" resource="transactions" />

    <a-table
      v-if="loadingTable || transactionsPage[0]"
      :data-source="transactionsPage"
      :loading="loadingTable"
      :columns="detailedTransactionColumns"
      :pagination="false"
      :expand-row-by-click="true"
      :scroll="{ x: 800 }"
      :row-class-name="(record) => oddOrEven(record.made_at)"
      :row-key="(transaction) => transaction.id"
      @change="(pagination, filters, sorter) => handleChange(pagination, filters, sorter)"
    >
      <template #amount="amount">
        <span class="d-flex align-items-center">
          {{ toMoney(amount) }}
        </span>
      </template>

      <template #description="description">
        <span class="d-flex align-items-center">
          {{ description }}
        </span>
      </template>

      <template #status="status">
        <span class="d-flex align-items-center">
          <a-tag v-if="status === 'pending'" :color="Colors.yellow">{{ status }}</a-tag>
          <a-tag v-if="status === 'paid'" :color="Colors.green">{{ status }}</a-tag>
        </span>
      </template>

      <template #made_at="date">
        <span>{{ date }}</span>
      </template>

      <template #type="type">
        <span class="d-flex align-items-center">
          {{ parseTransactionType(type) }}
        </span>
      </template>

      <template #expandedRowRender="record">
        <div>
          <div
            v-if="record.type === 'vacation-transaction' || record.type === 'salary-transaction'"
          >
            <a-row>
              <a-col :span="12" class="mb-3">
                <strong>Description:</strong>
                {{ record.description }}
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="6">
                <p>
                  <strong>Payroll Tax:</strong>
                </p>
                <p>{{ toPercentage(record.payroll_tax_percent) }}</p>
                <p>
                  <strong>Payroll Tax Amount:</strong>
                </p>
                <p>{{ toMoney(record.payroll_tax_amount) }}</p>
              </a-col>

              <a-col :span="6">
                <p>
                  <strong>Income tax:</strong>
                </p>
                <p>{{ toPercentage(record.income_tax_percent) }}</p>
                <p>
                  <strong>Income Tax Amount:</strong>
                </p>
                <p>{{ toMoney(record.income_tax_amount) }}</p>
              </a-col>

              <a-col v-if="record.type === 'salary-transaction'" :span="6">
                <p>
                  <strong>Vacation Percentage:</strong>
                </p>
                <p>{{ toPercentage(record.vacation_percent) }}</p>
                <p>
                  <strong>Vacation Amount:</strong>
                </p>
                <p>{{ toMoney(record.vacation_amount) }}</p>
              </a-col>

              <a-col :span="6">
                <div v-if="record.type === 'salary-transaction'">
                  <p>
                    <strong>Gross salary:</strong>
                  </p>
                  <p>{{ toMoney(record.gross_salary_amount) }}</p>
                </div>
                <p>
                  <strong>Paid out:</strong>
                </p>
                <p>{{ toMoney(record.paid_out_amount) }}</p>
              </a-col>
            </a-row>
            <a-row>
              <a-col
                v-if="
                  record.parental_days != null ||
                  record.vab_days != null ||
                  record.absence_days != null ||
                  record.sick_days != null
                "
                :span="22"
              >
                <hr style="border: 0.75px solid rgba(0, 0, 0, 0.1)" />
              </a-col>
            </a-row>
            <a-row style="padding-top: 5px">
              <a-col v-if="record.parental_days != null" :span="6">
                <strong>Parental Days:</strong>
                <p>{{ record.parental_days }}</p>
              </a-col>
              <a-col v-if="record.vab_days != null" :span="6">
                <strong>VAB:</strong>
                <p>{{ record.vab_days }}</p>
              </a-col>
              <a-col v-if="record.absence_days != null" :span="6">
                <strong>Absence Days:</strong>
                <p>{{ record.absence_days }}</p>
              </a-col>
              <a-col v-if="record.sick_days != null" :span="6">
                <strong>Sick Days:</strong>
                <p>{{ record.sick_days }}</p>
              </a-col>
            </a-row>
          </div>

          <div v-else>
            <a-col :span="23">
              <p>
                <strong>Description:</strong>
                {{ record.description }}
              </p>
              <receipt-details
                v-if="record.type === 'receipt-transaction' && record.receipt"
                :receipt="record.receipt"
              />
              <a-button
                v-if="record.type === 'plain-transaction' && record.reports && record.role"
                class="mt-0"
                type="primary"
                @click="() => $set(record, 'showReports', !record.showReports)"
                >{{ record.showReports ? "Hide details" : "Show details" }}</a-button
              >
            </a-col>
            <div v-if="record.showReports" class="mt-2 d-inline-block w-100">
              <a-row>
                <strong>
                  <a-col :span="4">Role</a-col>
                  <a-col :span="4">Date</a-col>
                  <a-col :span="4">Hours</a-col>
                  <a-col :span="4">Rate</a-col>
                  <a-col :span="4">Value</a-col>
                </strong>
              </a-row>

              <a-row v-for="report in record.reports" :key="report.id" class="mt-1">
                <a-col :span="4">{{ report.role.title }}</a-col>
                <a-col :span="4">{{ report.start_date }}</a-col>
                <a-col :span="4">{{ report.amount }}</a-col>
                <a-col :span="4">{{ toCurrency(report.hourly_rate) }}/h</a-col>
                <a-col :span="4">{{ toCurrency(report.amount * report.hourly_rate) }}</a-col>
              </a-row>
            </div>
          </div>
        </div>
      </template>
    </a-table>

    <a-pagination
      v-if="transactionsPagination && (loadingTable || transactionsPage[0])"
      :current="currentPage"
      :total="transactionsPagination.total"
      :show-size-changer="true"
      :page-size="pageSize"
      :page-size-options="['10', '25', '50', '100']"
      size="small"
      class="d-flex justify-content-end mt-2"
      @change="handleGetTransactionsPage"
      @showSizeChange="
        (pageNumber, newPageSize) => handleGetTransactionsPage(pageNumber, newPageSize)
      "
    />
  </div>
</template>

<script setup lang="ts">
import EmptyResourceTable from "@/modules/common/_components/EmptyResourceTable.vue";
import ReceiptDetails from "../../receipts/_components/ReceiptDetails.vue";
import { detailedTransactionColumns } from "../_utils/tables";
import Colors from "@/assets/scss/_colors.module.scss";
import { PaginationInterface } from "@/common/types";
import { TransactionInterface } from "../types";
import useMixin from "@/useMixin";
import moment from "@/date";

// Mixins
const { toCurrency, toMoney, parseTransactionType, toPercentage } = useMixin();

// Props
const props = defineProps({
  transactionsPage: { type: Array as () => Array<TransactionInterface>, default: () => [] },
  transactionsPagination: { type: Object as () => PaginationInterface, default: undefined },
  loadingTable: { type: Boolean, default: false },
  currentPage: { type: Number, default: 1 },
  pageSize: { type: Number, default: 25 },
});

// Emits
const emits = defineEmits(["get-transactions-page", "apply-sorting"]);

// Class Methods
const handleGetTransactionsPage = (page: number, pageSize: number): void => {
  emits("get-transactions-page", { page, pageSize });
};

const handleChange = (pagination: any, filters: any, sorter: any): void => {
  emits("apply-sorting", {
    sort: sorter.columnKey,
    order: sorter.columnKey ? (sorter.order === "descend" ? "desc" : "asc") : undefined,
  });
};

const oddOrEven = (date: string): string => {
  return moment(date).month() % 2 === 0 ? "even-row-table" : "odd-row-table";
};
</script>
