<template>
  <div>
    <empty-resource-table v-if="!hasData && !loadingTable" resource="transactions" />

    <a-table
      v-if="hasData || loadingTable"
      :data-source="transactionsPage"
      :loading="loadingTable"
      :columns="transactionsColumns"
      :row-selection="{ selectedRowKeys, onChange: (keys) => (selectedRowKeys = keys) }"
      :pagination="false"
      :expand-row-by-click="true"
      :scroll="{ x: 800 }"
      :row-class-name="(record) => oddOrEven(record.made_at)"
      :row-key="(transaction) => transaction.id"
      @change="(pagination, filters, sorter) => handleChange(pagination, filters, sorter)"
    >
      <template #amount="amount">
        <span class="d-flex align-items-center">
          {{ toMoney(amount) }}
        </span>
      </template>

      <template #description="description">
        <span class="d-flex align-items-center">
          {{ description }}
        </span>
      </template>

      <template #user="u, transaction">
        <span class="d-flex align-items-center">
          <span class="min-w-8 mr-2">
            <a-avatar :src="transaction.user.gravatar" size="small" />
          </span>
          <router-link
            :to="{ name: 'user', params: { id: transaction.user.id } }"
            @click.native.stop
          >
            {{ u.name }}
          </router-link>
        </span>
      </template>

      <template #made_at="date">
        <span>{{ date }}</span>
      </template>

      <template #type="type">
        <span class="d-flex align-items-center">
          {{ parseTransactionType(type) }}
        </span>
      </template>

      <template #status="type">
        <span class="d-flex align-items-center">
          <a-tag v-if="type === 'pending'" :color="Colors.yellow">{{ type.toUpperCase() }}</a-tag>
          <a-tag v-if="type === 'paid'" :color="Colors.green">{{ type.toUpperCase() }}</a-tag>
        </span>
      </template>

      <template #actions="text, transaction">
        <span class="d-flex align-items-center">
          <a-icon
            type="edit"
            class="action-default"
            @click.stop="handleEditTransactionModalVisible(transaction)"
          />
          <a-divider type="vertical" />
          <a-icon
            type="reload"
            class="action-default"
            @click.stop="handleAddRecurringJobModalVisible(transaction)"
          />
          <a-divider type="vertical" />
          <a-icon
            type="delete"
            class="action-danger"
            @click.stop="showDeleteTransactionConfirm(transaction)"
          />
        </span>
      </template>

      <template #expandedRowRender="transaction">
        <div>
          <div
            v-if="
              transaction.type === 'vacation-transaction' ||
              transaction.type === 'salary-transaction'
            "
          >
            <a-row>
              <a-col :span="12" class="mb-3">
                <strong>Description:</strong>
                {{ transaction.description }}
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="6">
                <p>
                  <strong>Payroll Tax:</strong>
                </p>
                <p>{{ toPercentage(transaction.payroll_tax_percent) }}</p>
                <p>
                  <strong>Payroll Tax Amount:</strong>
                </p>
                <p>{{ toMoney(transaction.payroll_tax_amount) }}</p>
              </a-col>

              <a-col :span="6">
                <p>
                  <strong>Income tax:</strong>
                </p>
                <p>{{ toPercentage(transaction.income_tax_percent) }}</p>
                <p>
                  <strong>Income Tax Amount:</strong>
                </p>
                <p>{{ toMoney(transaction.income_tax_amount) }}</p>
              </a-col>

              <a-col v-if="transaction.type === 'salary-transaction'" :span="6">
                <p>
                  <strong>Vacation Percentage:</strong>
                </p>
                <p>{{ toPercentage(transaction.vacation_percent) }}</p>
                <p>
                  <strong>Vacation Amount:</strong>
                </p>
                <p>{{ toMoney(transaction.vacation_amount) }}</p>
              </a-col>

              <a-col :span="6">
                <div v-if="transaction.type === 'salary-transaction'">
                  <p>
                    <strong>Gross salary:</strong>
                  </p>
                  <p>{{ toMoney(transaction.gross_salary_amount) }}</p>
                </div>
                <p>
                  <strong>Paid out:</strong>
                </p>
                <p>{{ toMoney(transaction.paid_out_amount) }}</p>
              </a-col>
            </a-row>
            <a-row>
              <a-col
                v-if="
                  transaction.parental_days != null ||
                  transaction.vab_days != null ||
                  transaction.absence_days != null ||
                  transaction.sick_days != null
                "
                :span="22"
              >
                <hr style="border: 0.75px solid rgba(0, 0, 0, 0.1)" />
              </a-col>
            </a-row>
            <a-row style="padding-top: 5px">
              <a-col v-if="transaction.parental_days != null" :span="6">
                <strong>Parental Days:</strong>
                <p>{{ transaction.parental_days }}</p>
              </a-col>
              <a-col v-if="transaction.vab_days != null" :span="6">
                <strong>VAB:</strong>
                <p>{{ transaction.vab_days }}</p>
              </a-col>
              <a-col v-if="transaction.absence_days != null" :span="6">
                <strong>Absence Days:</strong>
                <p>{{ transaction.absence_days }}</p>
              </a-col>
              <a-col v-if="transaction.sick_days != null" :span="6">
                <strong>Sick Days:</strong>
                <p>{{ transaction.sick_days }}</p>
              </a-col>
            </a-row>
          </div>
          <div v-else>
            <a-col :span="23">
              <p>
                <strong>Description:</strong>
                {{ transaction.description }}
              </p>
              <receipt-details
                v-if="transaction.type === 'receipt-transaction' && transaction.receipt"
                :receipt="transaction.receipt"
                :acl="baseAcl"
                @update-receipt="(payload) => $emit('update-receipt', payload)"
                @store-transaction="(transaction) => $emit('store-transaction', transaction)"
              />
            </a-col>
          </div>
        </div>
      </template>
    </a-table>

    <a-pagination
      v-if="transactionsPagination && hasData"
      :current="currentPage"
      :total="transactionsPagination.total"
      :show-size-changer="true"
      :page-size="pageSize"
      :page-size-options="['10', '25', '50', '100']"
      size="small"
      class="d-flex justify-content-end mt-2"
      @change="handleGetTransactionsPage"
      @showSizeChange="
        (pageNumber, newPageSize) => handleGetTransactionsPage(pageNumber, newPageSize)
      "
    />

    <edit-transaction-modal
      :visible="editTransactionModalVisible"
      :users="users"
      :user="user"
      :receipts="receipts"
      :old-transaction="transactionToBeEdited"
      @update-transaction="(transaction) => $emit('update-transaction', transaction, true)"
      @get-user="(userId) => $emit('get-user', userId)"
      @get-users="() => $emit('get-users')"
      @get-receipts="$emit('get-receipts')"
      @close="editTransactionModalVisible = false"
    />

    <bulk-update-elements-modal
      :visible="bulkUpdateTransactionsModalVisible"
      element-type="transaction"
      :elements="bulkTransactionsToBeEdited"
      :choices="choices"
      @bulk-update-elements="
        (transactionIds, newStatus) => $emit('bulk-update-transactions', transactionIds, newStatus)
      "
      @clear-selection="selectedRowKeys = []"
      @close="() => $emit('close-bulk-update-transactions-modal')"
    />

    <add-recurring-job-modal
      :visible="addRecurringJobModalVisible"
      :entity="recurringJobTransaction"
      :default-description="recurringJobDescription"
      type="recurring-transaction"
      @store-recurring-job="handleStoreRecurringJob"
      @close="addRecurringJobModalVisible = false"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, getCurrentInstance, watch, onMounted } from "vue";
import AddRecurringJobModal from "../../recurring_jobs/_components/AddRecurringJobModal.vue";
import EmptyResourceTable from "@/modules/common/_components/EmptyResourceTable.vue";
import { ProfileInterface, BaseAclInterface } from "@/modules/authentication/types";
import { RecurringJobPayloadInterface } from "@/modules/recurring_jobs/types";
import ReceiptDetails from "../../receipts/_components/ReceiptDetails.vue";
import EditTransactionModal from "./EditTransactionModal.vue";
import { ReceiptInterface } from "@/modules/receipts/types";
import Colors from "@/assets/scss/_colors.module.scss";
import { transactionsColumns } from "../_utils/tables";
import { PaginationInterface } from "@/common/types";
import { TransactionInterface } from "../types";
import useMixin from "@/useMixin";
import moment from "@/date";
import BulkUpdateElementsModal, {
  ChoiceInterface,
} from "../../common/_components/BulkUpdateElementsModal.vue";
import { useAuthenticationStore } from "@/modules/authentication/_store";

// Props
const props = defineProps({
  transactionsPagination: { type: Object as () => PaginationInterface, default: undefined },
  transactionsPage: { type: Array as () => Array<TransactionInterface>, default: () => [] },
  receipts: { type: Array as () => Array<ReceiptInterface>, default: () => [] },
  users: { type: Array as () => Array<ProfileInterface>, default: () => [] },
  bulkUpdateTransactionsModalVisible: { type: Boolean, default: false },
  user: { type: Object as () => ProfileInterface, default: undefined },
  withActionsColumn: { type: Boolean, default: false },
  loadingTable: { type: Boolean, default: false },
  currentPage: { type: Number, default: 1 },
  pageSize: { type: Number, default: 25 },
});

// Emits
const emits = defineEmits([
  "set-bulk-update-transactions-button",
  "get-transactions-page",
  "destroy-transaction",
  "store-recurring-job",
  "apply-sorting",
]);

// Pinia
const authenticationStore = useAuthenticationStore();
const baseAcl = computed<BaseAclInterface>(() => authenticationStore.baseAcl);

// Mixins
const { toMoney, parseTransactionType, toPercentage } = useMixin();

// Instances
const instance = getCurrentInstance();
const $confirm = instance?.proxy.$confirm;

// Data properties
const recurringJobTransaction = ref<TransactionInterface | undefined>(undefined);
const transactionToBeEdited = ref<TransactionInterface | undefined>(undefined);
const bulkTransactionsToBeEdited = ref<Array<TransactionInterface>>([]);
const recurringJobDescription = ref<string | undefined>(undefined);
const editTransactionModalVisible = ref<boolean>(false);
const addRecurringJobModalVisible = ref<boolean>(false);
const selectedRowKeys = ref<Array<number>>([]);
const choices = ref<Array<ChoiceInterface>>([
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "Paid",
    value: "paid",
  },
]);

// Computed properties
const hasData = computed(() => {
  return props.transactionsPage[0];
});

// Watchers
watch(selectedRowKeys, () => {
  emits("set-bulk-update-transactions-button", selectedRowKeys.value.length >= 1);
  bulkTransactionsToBeEdited.value = props.transactionsPage.filter(
    (t) => t.id && selectedRowKeys.value.includes(t.id)
  );
});

// Lifecycle hooks
onMounted(() => {
  const hasActions = transactionsColumns.some((c) => c.key === "actions");

  if (props.withActionsColumn && !hasActions) {
    transactionsColumns.push({
      title: "Actions",
      key: "actions",
      scopedSlots: { customRender: "actions" },
    });
  }
});

// Component methods
const handleEditTransactionModalVisible = (transaction: TransactionInterface): void => {
  transactionToBeEdited.value = transaction;
  editTransactionModalVisible.value = true;
};

const showDeleteTransactionConfirm = (transaction: TransactionInterface): void => {
  const mod = $confirm?.({
    title: "Are you sure you want to delete this Transaction?",
    content: transaction.recurring_job
      ? "This transaction is recurring, deleting this will also delete the recurring job"
      : "",
    okText: "Yes",
    okType: "danger",
    cancelText: "No",
    onOk() {
      emits("destroy-transaction", transaction.id);
      mod?.destroy();
    },
  });
};

const handleGetTransactionsPage = (page: number, pageSize: number): void => {
  emits("get-transactions-page", { page, pageSize });
};

const handleChange = (pagination: any, filters: any, sorter: any): void => {
  emits("apply-sorting", {
    sort: sorter.columnKey,
    order: sorter.columnKey ? (sorter.order === "descend" ? "desc" : "asc") : undefined,
  });
};

const oddOrEven = (date: string): string => {
  return moment(date).month() % 2 === 0 ? "even-row-table" : "odd-row-table";
};

const handleAddRecurringJobModalVisible = (transaction: TransactionInterface): void => {
  recurringJobDescription.value =
    "--Transaction--" +
    `\nUser: ${transaction.user.name}` +
    `\nAmount: ${toMoney(transaction.amount)}` +
    `\nType: ${transaction.type ? parseTransactionType(transaction.type) : ""}` +
    `\nDescription: ${transaction.description}` +
    "\n----------";
  recurringJobTransaction.value = transaction;
  addRecurringJobModalVisible.value = true;
};

const handleStoreRecurringJob = (recurringJob: RecurringJobPayloadInterface): void => {
  emits("store-recurring-job", recurringJob);
  addRecurringJobModalVisible.value = false;
};
</script>
