<template>
  <a-modal
    :visible="props.visible"
    :destroy-on-close="true"
    :width="760"
    title="The following roles have unlocked reports"
    @cancel="() => closeModal()"
  >
    <div>
      <a-table
        :data-source="props.rolesWarningSummary"
        :columns="rolesWarning"
        :row-key="(role) => role.id"
        :pagination="false"
        :scroll="{ x: true }"
      >
      </a-table>
    </div>
    <template #footer>
      <div class="d-flex justify-content-between">
        <div class="ml-auto">
          <a-button @click="closeModal">Close</a-button>
        </div>
      </div>
    </template>
  </a-modal>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { RolesWarningInterface } from "../types";

// Props
const props = defineProps({
  visible: { type: Boolean, default: false },
  rolesWarningSummary: { type: Array as () => Array<RolesWarningInterface>, default: () => [] },
});

// Emits
const emits = defineEmits(["close"]);

const closeModal = (): void => {
  emits("close");
};

// Data properties
const rolesWarning = ref<Array<{ title: string; dataIndex: string; key: string }>>([
  {
    title: "User",
    dataIndex: "user",
    key: "user",
  },
  {
    title: "Role",
    dataIndex: "title",
    key: "title",
  },
]);
</script>
